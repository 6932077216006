<template>
  <div :class="['delegation-item', {'delegation-item--is-active': item.isActive}]">
    <div
      class="delegation-item__inner"
      v-if="item"
      @click="goToDelegation"
    >
      <div class="delegation-item__column">
        <div class="delegation-item__label">
          От кого
        </div>
        <div
          class="delegation-item__value"
          v-html="item.fromFullName"
        />
      </div>
      <div class="delegation-item__column">
        <div class="delegation-item__label">
          Кому
        </div>
        <div
          class="delegation-item__value"
          v-html="item.toFullName"
        />
      </div>
      <div class="delegation-item__column delegation-item__date">
        <div class="delegation-item__label">
          Период замещения
        </div>
        <div class="delegation-item__value delegation-item__value--range">
          <base-date
            :date="item.dateFrom"
            show-year
            :show-time="false"
          />
          <span>-</span>
          <base-date
            :date="item.dateTo"
            show-year
            :show-time="false"
          />
        </div>
      </div>
      <div class="delegation-item__column delegation-item__prolongation">
        <esmp-button
          size="small"
          view="outline"
          @click.stop="prolongDelegation(item)"
        >
          Продлить
        </esmp-button>
      </div>
      <div class="delegation-item__column delegation-item__status">
        <div class="delegation-item__value">
          <span v-html="statusText(item)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const BaseDate = () => import('@/components/base/base-date');

export default {
  name: 'DelegationItem',
  components: {
    BaseDate,
  },
  props: {
    item: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  computed: {
    link() {
      return `/delegations/${this.item.id}`;
    },
  },
  methods: {
    statusText(item) {
      return item && item.isActive ? 'Активно' : 'Неактивно';
    },
    goToDelegation() {
      this.$router.push({
        path: this.link,
      });
    },
    prolongDelegation(item) {
      this.$emit('toggle-modal', {
        modalName: 'ModalDelegationProlongation',
        modalState: true,
        delegation: item,
      });
    },
  },
};
</script>

<style lang="scss">
  .delegation-item {
    $root: &;
    position: relative;
    margin-left: 8px;
    cursor: pointer;

    &::before {
      content: '';
      width: 24px;
      height: 76px;
      background-color: $color-gray-status-op-60;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      position: absolute;
      top: 0;
      left: -8px;
      z-index: 0;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__inner {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 100px 100px;
      grid-gap: $base-gutter;
      align-items: center;
      position: relative;
      z-index: 1;
      background-color: $color-white;
      padding: 20px;
      border-radius: 16px;

      @include for-size(phone-landscape-down) {
        grid-template-columns: 1fr 1fr 100px 100px;
      }
      @include for-size(phone-portrait-down) {
        grid-template-columns: 100%;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
    }

    &__label {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      color: $color-black-op-50;
    }

    &__value {
      font-size: 14px;
      line-height: 20px;

      &--range {
        display: flex;
        align-items: center;
      }
    }

    &__status {

      #{$root}__value {
        border-radius: 3px;
        background-color: $color-gray-status-op-15;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        padding: 4px 8px 4px 20px;
        position: relative;

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $color-black-op-50;
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @include for-size(phone-portrait-down) {
        order: 5;
      }
    }

    &__prolongation {
      @include for-size(phone-portrait-down) {
        order: 4;
      }
    }

    &__date {
      @include for-size(phone-landscape-down) {
        order: 5;
      }
      @include for-size(phone-portrait-down) {
        order: 3;
      }
    }

    &--is-active {
      &::before {
        background-color: $color-green-status-op-60;
      }

      #{$root}__status {
        #{$root}__value {
          background-color: $color-green-status-op-15;

          &::before {
            background-color: $color-green-status;
          }
        }
      }
    }
  }
</style>
